<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
    import { base, assets } from "$app/paths";
    import Image from '$lib/components/Image.svelte';
</script>

<header id="header">
    <div class="header-inner">
        <a class="left-block" href="{base}/">
            <Image isTransparent={true} id="header-main-logo" src="{assets}/img/common/tomarigi_logo" alt="logo" />
        </a>
        <div class="right-block">
            <div class="links">
                <a class="link-content" href="{base}/about">
                    <h1>とまりぎ亭とは</h1>
                    <p>ABOUT TOMARIGITEI</p>
                </a>
                <hr>
                <a class="link-content" href="{base}/artists">
                    <h1>作家一覧</h1>
                    <p>ARTISTS</p>
                </a>
            </div>
            <a class="request-button" href="{base}/request">
                <img src="{assets}/img/common/req_button.svg" alt="" />
                <div class="text-block">
                    <p>ご依頼はこちら</p>
                </div>
            </a>
        </div>
    </div>
</header>

<style lang="scss">
@use '$lib/assets/css/mq-settings' as mq;
@use '$lib/assets/css/variables' as var;

header {
    z-index: 2;
    display: flex;
    box-shadow: 0px 0px 16px #351C1155;
    height: 110px;
    width: 100%;
    justify-content: center;
    @include mq.mq($until: tablet) {
        height: 70px;
    }
}
:global(#header-main-logo) {
    @include var.hover-lighter-strong;
}
.header-inner {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    > * {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.left-block {
    :global(#header-main-logo) {
        max-height: 70%;
    }
}
.right-block {
    display: flex;
    align-items: center;
    @include mq.mq($until: tablet) {
        display: none;
    }
}
.links {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    margin-right: 40px;
    gap: 17px;
    @include mq.mq($until: tablet) {
        display: none;
    }
}
.link-content {
    text-decoration: none;
    h1 {
        font-family: var.$font-maru;
        font-size: 24px;
        font-weight: 700;
        margin: 0;
        color: var.$color-font-base;
    }
    p {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.8px;
        text-align: center;
        color: var.$color-accent;
    }
    @include var.hover-lighter-strong;
}
.request-button {
    position: relative;
    width: 225.905px;
    height: 46px;
    display: flex;
    .text-block {
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        color: white;
        font-size: font-size-title;
        p {
            font-size: 22px;
            font-family: var.$font-maru;
            font-weight: 700;
            transform: translate(-21px, -2px);
        }
    }
    @include mq.mq($until: desktop) {
        display: none;
    }
    @include var.hover-lighter-strong;
}
hr {
    width: 3.5px;
    height: auto;
    margin: 7.5px 5px;
    background-color: var.$color-accent;
}
</style>
