<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import { assets } from '$app/paths';
    import Image from '$lib/components/Image.svelte';

	const services = [
		{
			name: 'Twitter',
            icon: 'twitter',
			link: 'https://twitter.com/tomarigitei'
		},
		{
			name: 'Pixiv',
            icon: 'pixiv',
			link: 'https://www.pixiv.net/users/85403931'
		},
		{
			name: 'Ci-en',
            icon: 'cien',
			link: 'https://ci-en.dlsite.com/creator/14687'
		},
		{
			name: 'BOOTH',
            icon: 'booth',
			link: 'https://tomarigitei.booth.pm/'
		},
		{
			name: 'Mail',
            icon: 'mail',
			link: 'mailto:info@tomarigitei.com'
		}
	];
</script>

<footer>
	<div class="main">
		<div class="service-icons">
			{#each services as service}
				<a class="service-icon" href={service.link} target="_blank">
					<img class="icon-img" src="{assets}/img/services/white/{service.icon}.svg" alt={service.name} />
					<img class="balloon-img" src="{assets}/img/services/balloon/service_balloon_{service.icon}.svg" alt="" />
				</a>
			{/each}
		</div>
		<p class="copyright">🄫2023 TOMARIGITEI</p>
	</div>
    <div class="goichan">
        <button type="button" on:click={() => window.scroll({top: 0, behavior: 'smooth'})}><Image isTransparent={true} class="button-goichan" src="{assets}/img/common/page_top_button" alt="Goichan" /></button>
        <img class="button-pagetop" src="{assets}/img/common/page_top_balloon.svg" alt="" />
    </div>
</footer>

<style lang="scss">
    @use '$lib/assets/css/variables' as var;
    $btn-width: 234px;
    $btn-aspect: calc(90 / $btn-width);

    footer {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var.$color-dark-brown;
    }
    .banner {
        display: flex;
        justify-content: center;
        background-color: #493228;
    }
    .banner-inner {
        flex-grow: 1;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        max-width: ($btn-width * 4 + 150px);
        padding: 31px;

        button {
            width: $btn-width;
            height: ($btn-width * $btn-aspect);
            background-color: grey;
        }
    }
    .main {
        $footer-color: #aea49f;
        color: $footer-color;
        padding: 30px 20px 40px;
        background-color: color-dark-brown;
        > * {
            display: flex;
            justify-content: center;
        }
    }
    .service-icons {
        padding: 10px;
        gap: 15px;
    }
    .service-icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        img {
            width: 24px;
            height: 24px;
            transition: opacity 0.3s ease;
        }
        &:hover img {
            opacity: 1;
        }
    }
    .icon-img {
        opacity: 0.7;
    }
    .balloon-img {
        position: absolute;
        transform: scale(2.5) translateY(-12px);
        opacity: 0;
    }
    .copyright {
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        margin-top: 20px;
    }
    .goichan {
        position: absolute;
        right: 10px;
        top: -139px;
        button {
            border: none;
            background: transparent;
            padding: 0;
            cursor: pointer;
        }
        :global(.button-goichan) {
            width: 90px;
        }
        .button-pagetop {
            position: absolute;
            top: -62px;
            left: 0;
            right: 0;
            margin: auto;
            width: 50px;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        &:hover .button-pagetop {
            opacity: 1;
        }
    }
</style>
